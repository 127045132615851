export type LogLevel = 'error' | 'warn' | 'info' | 'silent' | 'debug';

class Logger {
    private level: LogLevel = 'info';

    setLevel(level: LogLevel) {
        this.level = level;
    }

    error(...args: unknown[]) {
        if (this.level !== 'silent') {
            console.error('\x1b[31m[ERROR]\x1b[0m', ...args);
        }
    }

    warn(...args: unknown[]) {
        if (this.level !== 'silent' && this.level !== 'error') {
            console.warn('\x1b[33m[WARN]\x1b[0m', ...args);
        }
    }

    info(...args: unknown[]) {
        if (this.level === 'info') {
            console.log('\x1b[36m[INFO]\x1b[0m', ...args);
        }
    }

    debug(...args: unknown[]) {
        if (
            this.level == 'debug' ||
            this.level == 'info' ||
            this.level == 'warn' ||
            this.level == 'error'
        ) {
            console.log('\x1b[36m[DEBUG]\x1b[0m', ...args);
        }
    }
    line() {
        console.log('-------------------------------------------------------------------------');
    }
}

export const logger = new Logger();

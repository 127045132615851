import { logger } from "../../../scripts/utils/logger.ts";

class HeaderManager {
    private nav: HTMLElement | null = null;
    private menuToggle: HTMLElement | null = null;

    constructor() {
        this.init();
    }

    private init(): void {
        // 等待 DOM 完全加载
        if (document.readyState === 'loading') {
            document.addEventListener('DOMContentLoaded', () => this.setupHeader());
        } else {
            this.setupHeader();
        }

        // 监听动态加载的元素
        const observer = new MutationObserver(() => {
            this.setupHeader();
        });

        observer.observe(document.body, {
            childList: true,
            subtree: true
        });
    }

    private setupHeader(): void {
        this.nav = document.querySelector('nav');
        this.menuToggle = document.querySelector('.menu-toggle');

        if (this.menuToggle && !this.menuToggle.dataset.initialized) {
            this.menuToggle.addEventListener('click', () => this.toggleMenu());
            this.menuToggle.dataset.initialized = 'true';
        }

        // 点击导航链接时关闭菜单
        if (this.nav && !this.nav.dataset.initialized) {
            const navLinks = this.nav.querySelectorAll('a');
            navLinks.forEach(link => {
                link.addEventListener('click', () => this.closeMenu());
            });
            this.nav.dataset.initialized = 'true';
        }
    }

    private toggleMenu(): void {
        if (!this.nav || !this.menuToggle) return;
        logger.info('toggleMenu');
        this.nav.classList.toggle('active');
        this.menuToggle.classList.toggle('active');
    }

    private closeMenu(): void {
        if (!this.nav || !this.menuToggle) return;
        this.nav.classList.remove('active');
        this.menuToggle.classList.remove('active');
    }
}

// 创建单例实例
const headerManager = new HeaderManager();

export default headerManager;
